import { useEffect } from "react";
import { useSignOut, useIsAuthenticated } from "react-auth-kit";
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import { APP_AUTH_TOKEN } from "../helpers/auth";

const PrivateRoute = ({ children, loginPath }: any) => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const signout = useSignOut();
  const location = useLocation();

  useEffect(() => {
    const localStorageAuthToken = localStorage.getItem("APP_AUTH_TOKEN");
    const appAuthToken = localStorage.getItem(APP_AUTH_TOKEN);

    if (!localStorageAuthToken && !appAuthToken) {
      signout();
      navigate(loginPath || "/login", { replace: true });
      return;
    }

    if (localStorageAuthToken && appAuthToken) {
      try {
        const currentTime = new Date();

        const data = JSON.parse(localStorageAuthToken);

        const expiredAt = new Date(data.expiresIn);

        if (currentTime > expiredAt) {
          localStorage.removeItem("APP_AUTH_TOKEN");
          sessionStorage.removeItem("APP_AUTH_TOKEN");
          localStorage.clear();

          signout();
          navigate(loginPath || "/login", { replace: true });
          return;
        }
      } catch (error) {
        console.error("Error parsing APP_AUTH_TOKEN:", error);

        localStorage.removeItem("APP_AUTH_TOKEN");
        sessionStorage.removeItem("APP_AUTH_TOKEN");
        localStorage.clear();

        signout();
        navigate(loginPath || "/login", { replace: true });
      }
    }
  }, [isAuthenticated]);

  if (isAuthenticated()) {
    return children;
  }

  return (
    <Navigate to={loginPath || "/login"} state={{ from: location }} replace />
  );
};

export default PrivateRoute;
